$bg-color: #f3f3f3;
$highlight-color: #ececec;
$animation-duration: 1.5s;

@keyframes background-shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.skeleton {
  background: linear-gradient(90deg, $bg-color 25%, $highlight-color 50%, $bg-color 75%);
  background-size: 200px 100%;
  animation: background-shimmer $animation-duration infinite linear;
}
